<template>
    <header>
        <img class="logo" :src="`${publicPath}banner-logo.jpg`">
    </header>
</template>

<script setup>
const publicPath = process.env.BASE_URL
</script>

<style lang="scss" scoped>
.logo {
  max-width: 800px;
  border-radius: 0.5em;
  margin-bottom: 1em;
}
</style>