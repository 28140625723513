<script setup>
import { provide, ref } from 'vue'
import { RouterView } from 'vue-router'

import HeaderPanel from '@/components/HeaderPanel.vue'

const token = ref('')
provide('token', token)
</script>

<template>
  <HeaderPanel />
  <router-view></router-view>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');

:root {
  --color-blue-navy: #01193E;
  --color-gold: #CEAB5C;
  --color-white-off: #EAE0D5;
  --color-brown-dark: #553300;
  --color-brown-mid: #A47A4D;
  --color-brown-red: #5B2231;
  --color-sand: #FFF7E6;
  --color-sand-dark: #F9EAC7;
  --color-shadow: rgba(0, 0, 0, 0.8);

  --color-input-valid-bg: #00B300;
  --color-input-valid-border: #008000;
  --color-input-invalid-bg: #FF3333;
  --color-input-invalid-border: #990000;

  --card-radius: 1rem;
  --card-bg-color: var(--color-sand-dark);
  --card-padding: 1em 1em 1.3em 1em;
}

* {
  font-family: 'Montserrat', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  font-weight: normal;
}

body {
  background-color: var(--color-sand);
  color: var(--color-brown-dark);
  padding-bottom: 8em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 1em;
}

#app {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

input {
  height: 2.5em;
  border-radius: 0.5em;
  border: 2px solid var(--color-brown-dark);
  padding: 0.5em;
  font-size: 1em;
  color: var(--color-brown-dark);
}

input:focus {
  outline: none;
  border-color: var(--color-gold);
}

select.validate-input {
  height: 2.5em;
  border-radius: 0.5em;
  border: 2px solid var(--color-brown-dark);
  padding: 0.5em;
  font-size: 1em;
  color: var(--color-brown-dark);
  width: auto !important;
}

select:focus {
  outline: none;
  border-color: var(--color-gold);
}

h1 {
  font-weight: 800;
  font-size: 2rem;
  margin-top: 0;
  padding-top: 0;
}

h2 {
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 0;
  padding-top: 0;
}

h3 {
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 0;
  padding-top: 0;
}

@media print {
  .noprint {
    display: none !important;
  }
}

.card {
  border-radius: var(--card-radius);
  background-color: var(--card-bg-color);
  padding: var(--card-padding);
}

button {
    border-radius: 0.8rem;
    border: none;
    padding: 0.8rem 1.5rem;
    font-size: 1em;
    cursor: pointer;
    box-shadow: 0px 12px 24px -12px var(--color-shadow);
}

button.primary {
  background-color: var(--color-blue-navy);
  color: var(--color-white-off);

  &:hover {
    background-color: var(--color-brown-red);
  }
}

button.secondary {
  color: var(--color-blue-navy);
  background-color: #EAEAEA;
  margin-left: 2.5em;

  &:hover {
    background-color: var(--color-brown-red);
    color: var(--color-white-off);
  }
}
</style>
