import store from 'store2'
import ky from 'ky'
export const apiHost = process.env.VUE_APP_BASE_URI

const _getHeaders = () => {
    return {
        'Authorization': `Bearer ${store('apiToken')}`,
        'Content-Type': 'application/json',
    }
}

const _getUrl = (endpoint) => {
    return `${apiHost}${endpoint}`
}

export const login = async (username, password) => {
    const endpoint = _getUrl('/auth/authenticate')
    const payload = {
        username,
        password,
    }
    const resp = await ky.post(endpoint, { json: payload }).json()
    // resp will be empty (204) if the credentials don't match
    // or the token in a json response if they do
    if (!resp) {
        store('apiToken', '')
        return false
    }
    else {
        store('apiToken', resp.token)
        return true
    }
}

export const getMembership = async () => {
    const endpoint = _getUrl('/membership/')
    const resp = await ky.get(endpoint, { headers: _getHeaders() }).json()
    return resp
}

export const signOut = () => {
    store('apiToken', '')
    // TODO: API call shouldn't do this...
    location.href = '/'
}

export const isAuthenticated = () => {
    return store('apiToken') !== ''
}