import { createWebHashHistory, createRouter } from 'vue-router'
import { isAuthenticated } from '@/lib/api'

const Login = () => import('@/views/LoginView.vue')
const PersonalYearMonthDay = () => import('./views/PersonalYearMonthDayView.vue')

const routes = [
    { path: '/', component: Login, meta: { requiresAuth: false } },
    { path: '/pymd', component: PersonalYearMonthDay, meta: { requiresAuth: true } },
]


export const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

router.beforeEach((to) => {
    // instead of having to check every route record with
    // to.matched.some(record => record.meta.requiresAuth)
    if (to.meta.requiresAuth && !isAuthenticated()) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        return {
            path: '/',
            // save the location we were at to come back later
            query: { redirect: to.fullPath },
        }
    }
})